import { z } from 'zod'
import { ActionConfidence } from './useAction.types'

export const emissionSumsSchema = z.strictObject({
  totalSum: z.number(),
  scope1Sum: z.number(),
  scope2Sum: z.number(),
  scope3Sum: z.number(),
})

export type EmissionSums = z.infer<typeof emissionSumsSchema>

export const yearlyEmissionsStatisticSchema = z.strictObject({
  companyId: z.number(),
  year: z.number(),
  baseYear: z.boolean(),
  capitalExpenditure: z.number(),
  netPresentValue: z.number(),
  emissionsActual: emissionSumsSchema.nullable(),
  emissionsTarget: emissionSumsSchema.nullable(),
  emissionsForecast: emissionSumsSchema.nullable(),
  emissionsAverageActualSum: z.number().nullable(),
  reductionTarget: z.number().nullable(),
  reductionPlanned: z.number().nullable(),
  reductionDone: z.number().nullable(),
  reductionRatioActual: z.number().nullable(),
  reductionRatioAverageTarget: z.number().nullable(),
  reductionRatioAverageActual: z.number().nullable(),
  confidence: z.nativeEnum(ActionConfidence).nullable(),
})

export type YearlyEmissionsStatistic = z.infer<
  typeof yearlyEmissionsStatisticSchema
>
