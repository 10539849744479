import { CompanyTreeFilterParams } from './useCompany.types'
import {
  YearlyEmissionsStatistic,
  yearlyEmissionsStatisticSchema,
} from './useYearlyEmissionsStatistics.types'
import { composeTypedQueryWithCompanyId } from './utils/factory'
import { Endpoint } from './utils/url'
import type { UseQueryOptions } from '@tanstack/vue-query'

export const useQueryYearlyEmissionsStatistics = <
  DataTransformed = YearlyEmissionsStatistic[]
>(
  params: CompanyTreeFilterParams = {
    includeCurrentCompany: true,
    includeChildCompanies: false,
    includeDescendantCompanies: false,
  },
  options?: Partial<
    UseQueryOptions<YearlyEmissionsStatistic[], unknown, DataTransformed>
  >
) =>
  composeTypedQueryWithCompanyId<YearlyEmissionsStatistic[]>(
    [Endpoint.YearlyEmissionsStatistics, params],
    yearlyEmissionsStatisticSchema.array()
  )(options)
